import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/navbar/logo.png';

const Navbar = () => {
    return (
        <div className="nav-outter d-flex justify-content-center w-100 border-bottom bg-white p-1 flex-wrap">
            <img src={logo} alt="can-you-brilieve" style={{ height: '35px', width: '230px', resizeMode: 'contain'}} />
            <div className="pl-3 text-format medium">
                <Link className="pl-1 pr-2 text-dark" to='/'>
                Home
                </Link>
                <Link className="pl-2 pr-1 text-dark" to='/whatilike'>
                Interests
                </Link>
                <Link className="pl-2 pr-2 text-dark" to='/about'>
                Locations
                </Link>
                <Link className="pl-2 pr-2 text-dark" to='/resume'>
                Work and School            
                </Link>
            </div>
            <div className="ml-auto pr-1 pt-1">
                <iframe title="bris-spotify-player" src="https://open.spotify.com/embed/playlist/1MZD1lRav6tmZoPFLMSWJv" width="80" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
        </div>
    );
  };
  
  export default Navbar;
  