import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import '../../styles/App.css';
import MainPage from '../MainPage/index';
import AboutPage from '../AboutPage/index';
import ResumePage from '../ResumePage/index';
import WhatILikePage from '../WhatILikePage/index';
import MakeupPage from '../MakeupPage/index';
import ArtPage from '../ArtPage/index';
import Navbar from '../../components/Navbar.js';
import NavbarSocial from '../../components/NavbarSocial.js';
import CreationsPage from '../CreationsPage/index';
import WhatsImportantPage from '../WhatsImportantPage/index';
import HomePage from '../HomePage/index';
import OutfitsPage from '../OutfitsPage/index';
import LinkTree from '../LinkTree/index';
import MakeupPageSocial from '../MakeupPageSocial';
import ArtPageSocial from '../ArtPageSocial';
import OutfitsPageSocial from '../OutfitsPageSocial';
import HomePageSocial from '../HomePageSocial';

function App() {
  return (
    <BrowserRouter>
      <div>
        {window.location.pathname === '/socials/linkpage'
        ? (
          <React.Fragment>
            <Switch>
              <Route exact path="/socials/linkpage" component={LinkTree}/>
            </Switch>
          </React.Fragment>)
        : <React.Fragment>
          {window.location.pathname.substring(0,8) === '/social/'
            ? (
              <React.Fragment>
                <NavbarSocial/>
                <Switch>
                  <Route exact path="/social/makeup" component={MakeupPageSocial}/>
                  <Route exact path="/social/outfits" component={OutfitsPageSocial}/>
                  <Route exact path="/social/homedecor" component={HomePageSocial}/>
                  <Route exact path="/social/art" component={ArtPageSocial}/>
                </Switch>
              </React.Fragment>)
            : <React.Fragment>
                <Navbar />
                <Switch>
                  <Route exact path="/" component={MainPage} />
                  <Route exact path="/about" component={AboutPage} />
                  <Route exact path="/resume" component={ResumePage} />
                  <Route exact path="/whatilike" component={WhatILikePage} />
                  <Route exact path="/makeup" component={MakeupPage} />
                  <Route exact path="/art" component={ArtPage} />
                  <Route exact path="/creations" component={CreationsPage} />
                  <Route exact path="/takeaction" component={WhatsImportantPage}/>
                  <Route exact path="/homedesign" component={HomePage}/>
                  <Route exact path="/fits" component={OutfitsPage}/>
                </Switch>
              </React.Fragment>}
          </React.Fragment>}
      </div>
    </BrowserRouter>
  );
}

export default App;
