import React, { Component } from 'react';
import officeImage from '../../images/office.png';
import schoolImage from '../../images/school.jpg';
import cpImage from '../../images/calpoly.png';
import sumitomoImage from '../../images/sumitomo.png';
import pdImage from '../../images/pd.png';
import { Row, Col, Container } from 'reactstrap';


class ResumePage extends Component {
    render () {
        return (
        <div className="resume-background h-100">
          <Container className="p-3 w-100">
            <Col className="mt-5 border border-dark bg-white p-2 flex-grow flex-wrap">
              <Row className="justify-content-center mb-4 flex-wrap">
                <img className="icon pr-1" src={officeImage} alt="work-logo" />
                <h1 className="h1">Work</h1>
              </Row>
              <Row className="justify-content-center mb-3 flex-wrap">
                <img className="icon pr-1" src={pdImage} alt="pagerduty" />  
                <div className="d-flex flex-column flex-wrap">        
                  <div className="d-flex flex-wrap large">Software Engineer I</div>
                  <div className="d-flex flex-wrap medium">PagerDuty - San Francisco</div>
                  <div className="d-flex flex-wrap small">Sept 2018 - Present </div>
                </div>
              </Row>
              <Row className="justify-content-center mb-3 flex-wrap">
                <img className="icon pr-1" src={sumitomoImage} alt="sumitomo" />  
                <div className="d-flex flex-column flex-wrap">        
                  <div className="d-flex flex-wrap large">I.T. Intern</div>
                  <div className="d-flex flex-wrap medium">Sumitomo Electric Wiring Systems - El Paso</div>
                  <div className="d-flex flex-wrap small">Summer 2017</div>
                </div>
              </Row>
              <Row className="justify-content-center mb-4 flex-wrap">
                <img className="icon pr-1" src={schoolImage} alt="school-logo" />
                <h1 className="h1">School</h1>
              </Row>
              <Row className="justify-content-center mb-3 flex-wrap">
                <img className="icon pr-1" src={cpImage} alt="calpoly" />  
                <div className="d-flex flex-column flex-wrap">        
                  <div className="d-flex flex-wrap large">Computer Engineering</div>
                  <div className="d-flex medium flex-wrap">Cal Poly - San Luis Obispo</div>
                  <div className="d-flex flex-wrap small">2014 – 2018</div>
                </div>
              </Row>
            </Col>
          </Container>
        </div>);
    }

}

export default ResumePage;