import { getYodaTranslation } from './util';

export const FETCH_YODA_REQUEST = 'fetchYodaRequest';
export const FETCH_YODA_SUCCESS = 'fetchYodaSuccess';
export const FETCH_YODA_ERROR = 'fetchYodaError';

function fetchYodaRequest() {
    return {
      type: FETCH_YODA_REQUEST
    };
  }
  
  function fetchYodaSuccess(data) {
    return {
      type: FETCH_YODA_SUCCESS,
      payload: data
    };
  }
  
  function fetchYodaError(error) {
    return {
      type: FETCH_YODA_ERROR,
      payload: error
    };
  }

  export function fetchYodaWords(message) {
    return async (dispatch) => {
      try {
        dispatch(fetchYodaRequest());
        const result = await getYodaTranslation(message);
        dispatch(fetchYodaSuccess(result.data));
      } catch (e) {
        dispatch(fetchYodaError(e));
      }
    };
  }