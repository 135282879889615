import React, { Component } from 'react';
import twitterImage from '../../images/mainpage/tweeter.png';
import igImage from '../../images/mainpage/ig.png';
import linkedInImage from '../../images/mainpage/linkedin.png';
import blobWave from '../../images/mainpage/wave.gif';
import bri1 from '../../images/mainpage/bri1.jpg';
import bri2 from '../../images/mainpage/bri2.jpg';
import { Link } from 'react-router-dom';

class MainPage extends Component {
    render () {
        return (
          <div className="main-background">
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              <div className="d-flex flex-row align-items-center">
                <div className="x-large animated-h">h</div>
                <div className="x-large animated-i">i </div>
                &nbsp;                
                &nbsp;                
                <div className="x-large animated-f">f</div>
                <div className="x-large animated-r">r</div>
                <div className="x-large animated-i-2">i</div>
                <div className="x-large animated-e">e</div>
                <div className="x-large animated-n">n</div>
                <div className="x-large animated-d">d</div>
                <img className="animated-blob" alt="blob-wave" src={blobWave} style={{ width: '50px'}}></img>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-around w-100 flex-wrap">
                <img alt="bri1" src={bri1} style={{ height: '450px' }} className="mt-2 border border-dark"></img>
                <div className="border border-dark p-5 mt-2 medium" style={{ width: '400px' }}>
                  This is a site filled with some Bri info + creations + other stuff. Click around. Play some music 
                  (it'll play the full songs if you're logged into Spotify on your browser of choice). 
                  Let's be friends :-) 
                </div>
                <img alt="bri2" src={bri2} style={{ height: '450px' }} className="mt-2 border border-dark"></img>
              </div>
              <div className="d-flex flex-row pt-2 m-2 align-items-center medium border border-dark">
                <Link className="m-3 text text-dark" to="/takeaction">What's Important To Me</Link>
              </div>
              <div className="d-flex flex-row pt-2 m-2 align-items-center medium">
                Where to find me
              </div>
              <div className="d-flex flex-row mb-2 align-items-center">
                <a href="https://www.instagram.com/brisolorzano/" target="_blank" rel="noopener noreferrer"><img className="icon-sm m-2" src={igImage} alt="ig logo" /></a>
                <a href="https://twitter.com/canubrilieveit" target="_blank" rel="noopener noreferrer"><img className="icon-sm m-2" src={twitterImage} alt="twitter logo" /></a>
                <a href="https://www.linkedin.com/in/brianna-solorzano-12287510a/" target="_blank" rel="noopener noreferrer"><img className="icon-sm m-2" src={linkedInImage} alt="linkedIn logo" /></a>
              </div>
            </div>
          </div>);
    }

}

export default MainPage;