import React, { Component } from 'react';
import look1 from '../../images/makeup/look1.jpg';
import look2 from '../../images/makeup/look2.jpg';
import look3 from '../../images/makeup/look3.jpg';
import look4 from '../../images/makeup/look4.jpg';
import look5 from '../../images/makeup/look5.jpg';
import look6 from '../../images/makeup/look6.jpg';
import foundation from '../../images/makeup/foundation.png';
import concealer from '../../images/makeup/concealer.png';
import eyeliner from '../../images/makeup/eyeliner.png';
import mascara from '../../images/makeup/mascara.png';
import Emoji from 'a11y-react-emoji';

class MakeupPageSocial extends Component {
    render () {
        return (
        <div>
            <div className="d-flex flex-column align-items-center w-100 p-5">
              <div className="d-flex flex-column flex-wrap">
                <div className="d-flex flex-row flex-wrap">
                  <img className="makeup-looks p-1" src={look1} alt="makeup look 1" />
                  <img className="makeup-looks p-1" src={look2} alt="makeup look 2" />
                  <img className="makeup-looks p-1" src={look3} alt="makeup look 3" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="makeup-looks p-1" src={look4} alt="makeup look 4" />
                  <img className="makeup-looks p-1" src={look5} alt="makeup look 5" />
                  <img className="makeup-looks p-1" src={look6} alt="makeup look 6" />
                </div>
              </div>
              <div className="border border-dark mt-4 mb-2 d-flex flex-column align-items-center">
                <div className="p-2 text-center">
                  <h1 className="h1">Current Favorites</h1>
                  <div className="mt-3 d-flex flex-row">
                    <div className="d-flex flex-column align-items-center p-2">
                      <p className="medium mb-0">Foundation</p>
                      <img className="makeup-items" src={foundation} alt="foundation" />
                      <p className="medium mb-0">YSL</p>
                      <p className="medium mb-0">All Hours Foundation</p>
                      <p className="small mb-0">BD30 Warm Almond</p>
                    </div>
                    <div className="d-flex flex-column align-items-center p-2">
                      <p className="medium mb-0">Concealer</p>
                      <img className="makeup-items" src={concealer} alt="concealer" />
                      <p className="medium mb-0">Estée Lauder</p>
                      <p className="medium mb-0">Double Wear Concealer</p>
                      <p className="small mb-0">1W Light</p>
                    </div>
                    <div className="d-flex flex-column align-items-center p-2">
                      <p className="medium mb-0">Eyeliner</p>
                      <img className="makeup-items" src={eyeliner} alt="eyeliner" />
                      <p className="medium mb-0">NYX</p>
                      <p className="medium mb-0">Matte Liquid Liner</p>
                      <p className="small mb-0">Black</p>
                    </div>
                    <div className="d-flex flex-column align-items-center p-2">
                      <p className="medium mb-0">Mascara</p>
                      <img className="makeup-items" src={mascara} alt="mascara" />
                      <p className="medium mb-0">Tarte</p>
                      <p className="medium mb-0">Lights, camera, lashes mascara</p>
                      <p className="small mb-0">Black</p>
                    </div>
                  </div>
                </div>
              </div>
              <a className="medium" href="https://www.instagram.com/beautywithbri/" target="_blank" rel="noopener noreferrer">
                For more beauty with bri <Emoji symbol="✨" />
              </a>
            </div>
        </div>
        );
    }

}

export default MakeupPageSocial;