import React, { Component } from 'react';
import look1 from '../../images/homepage/1.JPG';
import look2 from '../../images/homepage/2.JPG';
import look4 from '../../images/homepage/4.JPG';
import look5 from '../../images/homepage/5.JPG';
import look6 from '../../images/homepage/6.JPG';
import look7 from '../../images/homepage/7.JPG';
import look8 from '../../images/homepage/8.JPG';
import look9 from '../../images/homepage/9.JPG';
import look10 from '../../images/homepage/10.JPG';

class HomePage extends Component {
    render () {
        return (
        <div>
            <div className="d-flex flex-column align-items-center w-100 p-5">
              <div className="d-flex flex-column flex-wrap">
              <div className="large d-flex flex-row flex-wrap justify-content-center align-items-center">
                    I like to have pretty things to stare at in my home.
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look1} style={{width: '330px', height: '420px'}} alt="look 1" />
                  <img className="p-1" src={look2} style={{width: '330px', height: '420px'}} alt="look 2" />
                  <img className="p-1" src={look10} style={{width: '330px', height: '420px'}} alt="look 3" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look4} style={{width: '330px', height: '420px'}} alt="makeup look 4" />
                  <img className="p-1" src={look5} style={{width: '330px', height: '420px'}} alt="makeup look 5" />
                  <img className="p-1" src={look6} style={{width: '330px', height: '420px'}} alt="makeup look 6" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look7} style={{width: '330px', height: '420px'}} alt="makeup look 4" />
                  <img className="p-1" src={look8} style={{width: '330px', height: '420px'}} alt="makeup look 5" />
                  <img className="p-1" src={look9} style={{width: '330px', height: '420px'}} alt="makeup look 6" />
                </div>
                <div className="large d-flex flex-row flex-wrap justify-content-center align-items-center">
                    If you want some help picking out pretty things for your space hmu :-) 
                </div>
              </div>
              </div>
        </div>
        );
    }

}

export default HomePage;