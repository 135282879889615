import React, { Component } from 'react';
import look1 from '../../images/outfitspage/bri.png';
import look2 from '../../images/outfitspage/bri2.JPG';
import look4 from '../../images/outfitspage/bri3.jpg';
import look5 from '../../images/outfitspage/bri4.JPG';
import look6 from '../../images/outfitspage/bri5.JPG';
import look7 from '../../images/outfitspage/bri6.JPG';
import look8 from '../../images/outfitspage/bri7.jpg';
import look9 from '../../images/outfitspage/bri8.jpg';
import look10 from '../../images/outfitspage/bri9.JPG';
import look11 from '../../images/outfitspage/bri10.jpg';
import look12 from '../../images/outfitspage/bri11.JPG';
import look13 from '../../images/outfitspage/bri12.JPG';
import look14 from '../../images/outfitspage/bri13.JPG';
import look15 from '../../images/outfitspage/bri14.JPG';
import look16 from '../../images/outfitspage/bri15.JPG';
import look18 from '../../images/outfitspage/bri17.JPG';


class OutfitsPage extends Component {
    render () {
        return (
        <div>
            <div className="d-flex flex-column align-items-center w-100 p-5">
              <div className="d-flex flex-column flex-wrap">
                <div className="large d-flex flex-row flex-wrap justify-content-center align-items-center">
                    I like to wear pretty things.
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look14} style={{width: '330px', height: '330px'}} alt="makeup look 5" />
                  <img className="p-1" src={look13} style={{width: '330px', height: '330px'}} alt="makeup look 4" />
                  <img className="p-1" src={look15} style={{width: '330px', height: '330px'}} alt="makeup look 6" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <img className="p-1" src={look4} style={{width: '330px', height: '420px'}} alt="makeup look 4" />
                    <img className="p-1" src={look1} style={{width: '330px', height: '480px'}} alt="look 1" />
                    <img className="p-1" src={look5} style={{width: '330px', height: '420px'}} alt="makeup look 5" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look8} style={{width: '330px', height: '550px'}} alt="makeup look 5" />
                  <img className="p-1" src={look12} style={{width: '330px', height: '540px'}} alt="makeup look 6" />
                  <img className="p-1" src={look9} style={{width: '330px', height: '550px'}} alt="makeup look 6" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look2} style={{width: '330px', height: '420px'}} alt="look 2" />
                  <img className="p-1" src={look6} style={{width: '330px', height: '550px'}} alt="makeup look 6" />
                  <img className="p-1" src={look18} style={{width: '330px', height: '420px'}} alt="look 3" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look10} style={{width: '330px', height: '540px'}} alt="makeup look 4" />
                  <img className="p-1" src={look7} style={{width: '330px', height: '550px'}} alt="makeup look 4" />
                  <img className="p-1" src={look11} style={{width: '330px', height: '540px'}} alt="makeup look 5" />
                </div>
                <div className="d-flex flex-row flex-wrap">
                  <img className="p-1" src={look16} style={{width: '330px', height: '330px'}} alt="makeup look 4" />
                </div>
                <div className="large d-flex flex-row flex-wrap justify-content-center align-items-center">
                    More outfits loading... 
                </div>
              </div>
              </div>
        </div>
        );
    }

}

export default OutfitsPage;