import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchYodaWords } from '../../features/yoda/actions';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import yodaBri from '../../images/createpage/ezgif.com-crop.gif';
import introPic from '../../images/createpage/walk.gif';


class CreationsPage extends Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleTextInputChange = this.handleTextInputChange.bind(this);
      this.state = {
        loaded: false,
        yodaInput: '',
        inputInProcess: false,
      };
    }
  
    async componentDidMount() {  
      this.setState({ loaded: true });
    }

    async handleSubmit(event) {
      event.preventDefault();
      await this.props.fetchYodaWords(this.state.yodaInput);
    }

    handleTextInputChange(event) {
      this.setState( { inputInProcess: true });
      this.setState({ yodaInput: event.target.value }, () => {
      });
    }

 
    render() {
        return(
        <div className="p-3 mx-auto d-flex flex-column">
          <h2 className="large text-center">Hello Padawans, your Jedi training begins here:</h2>
          <Form className="justify-content-center" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <div className="w-100 text-center d-block mb-2">
                    <img alt="intro-star-wars" src={introPic}></img>
                  </div>
                  <Label className="medium w-100 text-center d-block pt-3" style={{ lineHeight: '150%' }}>
                    Learn how to speak like Yoda
                  </Label>
                  <Input 
                    style={{ width: '350px', position: 'relative', margin: '0 auto'}}
                    type="text"
                    name=""
                    id="yodaText"
                    placeholder="Type some words here"
                    value={this.state.yodaInput}
                    onChange={this.handleTextInputChange}
                  />
                </FormGroup>
                <FormGroup>
                <div className="w-100 text-center d-block">
                  <Button type="submit">Submit</Button>
                </div>
                </FormGroup>
                {this.props.yodaTranslation !== '' &&
                  <div className="d-flex flex-column text-center align-items-center w-100">
                    <div className="medium">{this.props.yodaTranslation}</div>
                    <img alt="bri-yoda" style={{ width: '350px' }} src={yodaBri}></img>
                  </div>
                }
            </Form>
          <div className="medium mt-4 text-center">Practice this until you can't tell the difference between yourself and Yoda. More coming soon.</div>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    yodaTranslation: state.yodaData.yodaMessage,
    isFetching: state.yodaData.isFetching,
    error: state.yodaData.error,
  });
  const mapActionsToProps = { fetchYodaWords };
  export default connect(mapStateToProps, mapActionsToProps)(CreationsPage);
