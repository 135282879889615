import React, { Component } from 'react';
import epImage from '../../images/ep.jpg';
import sfImage from '../../images/sf.jpg';
import sloImage from '../../images/slo.jpg';
import { Button } from 'reactstrap';
import Emoji from 'a11y-react-emoji';


class AboutPage extends Component {
    constructor() {
        super();
        this.onClickSF = this.onClickSF.bind(this);
        this.onClickSLO = this.onClickSLO.bind(this);
        this.onClickEP = this.onClickEP.bind(this);


        this.state = {
            clickedEP: false,
            clickedSLO: false,
            clickedSF: false,
            classNameEP: 'text-section',
            classNameSLO: 'text-section',
            classNameSF: 'text-section'
        }
    }

    onClickSF() {
        if (this.state.clickedSF === false) {
            this.setState({
                clickedSF: true,
                classNameSF: 'text-section-on-click'
            });
        }
        else {
            this.setState({
                clickedSF: false,
                classNameSF: 'text-section'
            });
        }
    }

    onClickSLO() {
        if (this.state.clickedSLO === false) {
            this.setState({
                clickedSLO: true,
                classNameSLO: 'text-section-on-click'
            });
        }
        else {
            this.setState({
                clickedSLO: false,
                classNameSLO: 'text-section'
            });
        }
    }

    onClickEP() {
        if (this.state.clickedEP === false) {
            this.setState({
                clickedEP: true,
                classNameEP: 'text-section-on-click'
            });
        }
        else {
            this.setState({
                clickedEP: false,
                classNameEP: 'text-section'
            });
        }
    }
    render () {
        return (
        <div>
            <div className="text-center w-100 mt-4 medium">Click a pic to learn more <Emoji symbol="🥳" /></div>
            <div className="d-flex justify-content-center w-100 mt-1 flex-wrap">
                <div className="location-section pr-2 pt-1">
                    <Button onClick={this.onClickSF} className="border-0 bg-transparent p-0">
                    <img className="location-image" src={sfImage} alt="locations" />
                    <div className={`d-flex h-100 ${this.state.classNameSF}`}>
                        <div className="text-center m-auto pl-2 pr-2 medium">
                            I currently live in San Francisco, working as a software engineer at a tech company called PagerDuty.
                            I moved over here in September 2018 and I still get lost in most areas. I adopted my very own first dog
                            here and now I have a four legged bff that loves going around the city smelling alllll the smells.
                        </div>
                        
                    </div>
                    </Button>
                </div>
                <div className="location-section pr-2 pt-1">
                    <Button onClick={this.onClickEP} className="border-0 bg-transparent p-0">
                    <img className="location-image" src={epImage} alt="locations" />
                    <div className={`d-flex h-100 ${this.state.classNameEP}`}>
                        <div className="text-center m-auto pl-2 pr-2 medium">
                            I was born and raised in El Paso, Texas. Growing up there for me was lots of soccer games, family 
                            gatherings, and really good food. El Paso is filled with hidden gems and lots of heart. Everyone from 
                            there is proud to be from there, including me. Growing up in El Paso made me who I am today. I hope I
                            can one day give back to the community for everything it gave me. 
                        </div>
                    </div>
                    </Button>
                </div>
                <div className="location-section pr-2 pt-1">
                    <Button onClick={this.onClickSLO} className="border-0 bg-transparent p-0">
                    <img className="location-image" src={sloImage} alt="locations" />
                    <div className={`${this.state.classNameSLO} d-flex h-100`}>
                        <div className="text-center m-auto pl-2 pr-2 medium">
                            I got my undergraduate degree in computer engineering at Cal Poly in San Luis Obispo. I went to Cal Poly 
                            without knowing anyone else there, which I didn't fully realize until my parents left after moving me 
                            in. I met so many important people in my life there and had so many great memories there, choosing 
                            Cal Poly was one of the best decisions I've made in my life. Sounds cheesy but my parents named me 
                            Bri(e) for a reason. 
                        </div>
                    </div>
                    </Button>
                </div>
            </div>
        </div>
        );
    }

}

export default AboutPage;