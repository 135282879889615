import {
    FETCH_YODA_REQUEST,
    FETCH_YODA_SUCCESS,
    FETCH_YODA_ERROR
} from './actions'

export default function yodaReducer(
    state = {
      yodaMessage: '',
      isFetching: false,
      error: null
    },
    action
  ) {
    switch (action.type) {
      case FETCH_YODA_REQUEST: {
        return {
          ...state,
          isFetching: true
        };
      }
      case FETCH_YODA_ERROR: {
        return {
            ...state,
            isFetching: false,
            error: action.payload
        }
      }
      case FETCH_YODA_SUCCESS: {
        return {
            ...state,
            isFetching: false,
            yodaMessage: action.payload.contents.translated
        }
      }
      default:
      return state;
    }
}