import React, { Component } from 'react';


class WhatsImportantPage extends Component {
    render () {
        return (
          <div className="main-background">
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              <div className="d-flex flex-row align-items-center justify-content-around w-100 flex-wrap">
                <div className="border border-dark p-5 mt-5 medium" style={{ width: '400px' }}>
                  <p>Black Lives Matter.</p>
                  <p>Donate.</p>
                  <p>Sign petitons.</p>
                  <p>Educate yourself.</p>
                  <a href="https://blacklivesmatters.carrd.co/" target="_blank" rel="noopener noreferrer">A great place to start is here (click).</a>
                </div>
              </div>
              <div className="large d-flex flex-row flex-wrap justify-content-center align-items-center mt-4">
                    More resources and ways to help and take action for causes that are important to me *coming soon*
            </div>
            </div>
          </div>);
    }
}

export default WhatsImportantPage;