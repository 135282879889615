import React, { Component } from 'react';
import { Button } from 'reactstrap';
import cactus from '../../images/corner.png';
import moon from '../../images/moon.png';
import tiktoklogo from '../../images/tiktoklogo.png';
import iglogo from '../../images/ig.png';
import linklogo from '../../images/linklogo.png';
import makeuplogo from '../../images/makeuplogo.png';
import shoppingbag from '../../images/shoppingbag.png';
import twitterlogo from '../../images/twitterlogo.png';
import amazonlogo from '../../images/linktree/amazon.png';
import ltklogo from '../../images/linktree/ltk.png';

class LinkTree extends Component {
    render () {
        return (
         <div className="h-100 main-background-linktree position-relative">
            <img alt="moon" src={moon} style={{ height: '77px', width: '77px' }} ></img>
         <div className="d-flex flex-column">  
            <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="p-3 linktree-style">
            <div className="d-flex flex-row large-medium mb-2 justify-content-center">
               Can You
               <div className="ml-1 animated-h">B</div>
               <div className="animated-i">r</div>
               <div className="animated-f">i</div>
               <div className="animated-r">l</div>
               <div className="animated-i-2">i</div>
               <div className="animated-e">e</div>
               <div className="animated-n">v</div>
               <div className="mr-1 animated-d">e</div>
               It?
            </div>
            <Button href="https://www.tiktok.com/@canyoubrilieveit" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark mt-3 linktree-button">
               <img alt="tiktok" src={tiktoklogo} style={{ height: '18px', width: '18px' }} className="mr-2"></img>TikTok
            </Button>
            <Button href="https://www.instagram.com/canyoubrilieve.it/" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark linktree-button">
               <img alt="instagram" src={iglogo} style={{ height: '18px', width: '18px' }} className="mr-2"></img>Instagram
            </Button>
            <Button href="https://www.shopLTK.com/explore/Canyoubrilieveit" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark linktree-button">
               <img alt="clicklink" src={ltklogo} style={{ height: '20px', width: '18px' }} className="mr-2"></img>LTK Links
            </Button>
            <Button href="https://www.amazon.com/shop/briiiii" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark linktree-button">
               <img alt="clicklink" src={amazonlogo} style={{ height: '20px', width: '18px' }} className="mr-2"></img>Amazon Links
            </Button>
            <Button href="https://docs.google.com/document/d/1PPSHaowOlmhFiovBfPFHE24zdF27eobgcY5wZ4z3WPY/edit?usp=sharing" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark linktree-button">
               <img alt="clicklink" src={linklogo} style={{ height: '20px', width: '18px' }} className="mr-2"></img>Other Links
               <div className="font-small position-absolute" style={{marginTop: '26px', marginLeft: '10px', fontSize: '8px'}}>it's a google doc don't judge me</div>
            </Button>
            <Button href="https://www.depop.com/brisolo/" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark linktree-button">
               <img alt="depop" src={shoppingbag} style={{ height: '20px', width: '18px' }} className="mr-2"></img>Depop (help me clean out my closet plz)
            </Button>
            <Button href="https://twitter.com/canubrilieveit" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark linktree-button">
               <img alt="twitter" src={twitterlogo} style={{ height: '18px', width: '18px' }} className="mr-2"></img>Twitter
            </Button>
            <Button href="https://www.instagram.com/beautywithbri/" target="_blank" className="d-flex flex-row pt-2 m-2 align-items-center justify-content-center medium border border-dark text-dark linktree-button">
               <img alt="makeupig" src={makeuplogo} style={{ height: '25px', width: '10px' }} className="mr-2"></img>My Makeup Insta
            </Button>
            </div>
            </div>
         </div>
         <img className="position-absolute" alt="cactus" src={cactus} style={{ height: '110px', width: '85px', bottom: '0', right: '0' }} ></img>
      </div>);
    }

}

export default LinkTree;