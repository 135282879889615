import React, { Component } from 'react';
import look1 from '../../images/artpage/beautyTemplatePIC.png';
import look2 from '../../images/artpage/citytrans.png';
import look3 from '../../images/artpage/eptrans.png';
import look4 from '../../images/artpage/fittrans.png';
import look5 from '../../images/artpage/foodtrans.png';
import look6 from '../../images/artpage/hug.png';
import look7 from '../../images/artpage/listening.png';
import look8 from '../../images/artpage/picstrans.png';
import look9 from '../../images/artpage/runningbri.png';
import look10 from '../../images/artpage/ipadart/Untitled_Artwork1.jpg';
import look11 from '../../images/artpage/ipadart/Untitled_Artwork2.jpg';
import look12 from '../../images/artpage/ipadart/Untitled_Artwork3.jpg';
import look19 from '../../images/artpage/ipadart/Untitled_Artwork10.jpg';
import look20 from '../../images/artpage/ipadart/Untitled_Artwork11.jpg';
import look21 from '../../images/artpage/ipadart/Untitled_Artwork12.jpg';
import look22 from '../../images/artpage/ipadart/Untitled_Artwork13.jpg';
import look23 from '../../images/artpage/ipadart/Untitled_Artwork14.jpg';
import look24 from '../../images/artpage/ipadart/Untitled_Artwork15.jpg';
import look25 from '../../images/artpage/ipadart/Untitled_Artwork16.jpg';
import look26 from '../../images/artpage/ipadart/Untitled_Artwork17.jpg';
import look27 from '../../images/artpage/ipadart/Untitled_Artwork18.jpg';
import look28 from '../../images/artpage/ipadart/Untitled_Artwork19.jpg';
import look29 from '../../images/artpage/ipadart/Untitled_Artwork20.jpg';

class ArtPage extends Component {
    render () {
        return (
        <div>
            <div className="d-flex flex-column align-items-center w-100 p-5">
              <div className="d-flex flex-column flex-wrap">
                <div className="large d-flex flex-row flex-wrap justify-content-center align-items-center">
                    I sometimes like to digitally draw.
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <img
                    style={{width: '330px', height: '500px'}}
                    src={look28}
                    alt="look10"/>
                    <img
                    style={{width: '330px', height: '500px'}}
                    src={look29}
                    alt="look11"/>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look10}
                    alt="look10"/>
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look11}
                    alt="look11"/>
                    <img
                    style={{width: '330px', height: '320px'}}
                    src={look12}
                    alt="look12"/>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look25}
                    alt="look16"/>
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look26}
                    alt="look17"/>
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look27}
                    alt="look18"/>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look19}
                    alt="look13"/>
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look20}
                    alt="look14"/>
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look21}
                    alt="look15"/>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look22}
                    alt="look16"/>
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look23}
                    alt="look17"/>
                    <img
                    style={{width: '330px', height: '420px'}}
                    src={look24}
                    alt="look18"/>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                  <img className="art-pic p-1" src={look5} style={{width: '330px', height: '320px'}} alt="art 5" />
                  <img className="art-pic p-1" src={look7} style={{width: '300px'}} alt="art 4" />
                  <img className="art-pic p-1" src={look3} style={{width: '360px', height: '320px'}} alt="art 3" />
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                  <img className="art-pic p-1" src={look6} style={{width: '300px'}} alt="art 6" />
                  <img className="art-pic p-1" src={look4} style={{width: '300px'}} alt="art 4" />
                  <img className="art-pic p-1" src={look1} style={{width: '300px'}} alt="art 1" />
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                  <img className="art-pic p-1" src={look2} style={{width: '300px'}} alt="art 2" />
                  <img className="art-pic p-1" src={look9} style={{width: '300px'}} alt="art 6" />
                  <img className="art-pic p-1" src={look8} style={{width: '300px'}} alt="art 5" />
                </div>
                <div className="large d-flex flex-row flex-wrap justify-content-center align-items-center">
                    If you want me to draw something for ya, <a href="https://www.instagram.com/brisolorzano/" target="_blank" rel="noopener noreferrer">message me</a> :)
                </div>
              </div>
            </div>
        </div>
        );
    }

}

export default ArtPage;