import React, { Component } from 'react';
import artImage from '../../images/art.jpg';
import makeupImage from '../../images/makeup/makeup.jpg';
import homeImage from '../../images/homepage/home.png';
import clothesImage from '../../images/outfitspage/clothes.jpg';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Emoji from 'a11y-react-emoji'


class WhatILikePage extends Component {
    render () {
        return (
        <div>
          <div className="d-flex justify-content-center w-100 mt-5 flex-wrap">
                <div className="hobbies-section pr-2 pt-1">
                    <img className="hobbies-image" src={artImage} alt="art" />
                    <div className="d-flex text-section h-100">
                      <div className="text-center m-auto pl-2 pr-2">
                        <Link to="/art">
                          <Button>
                            <Emoji symbol="✨" />See more <Emoji symbol="✨" />
                          </Button>   
                        </Link>
                      </div>  
                    </div>
                </div>
                <div className="hobbies-section pr-2 pt-1">
                    <img className="hobbies-image" src={makeupImage} alt="makeup" />
                    <div className="text-section d-flex h-100">
                      <div className="text-center m-auto pl-2 pr-2">
                        <Link to="/makeup">
                          <Button>
                            <Emoji symbol="✨" />See more <Emoji symbol="✨" />
                          </Button>
                          </Link>   
                      </div>
                    </div>
                </div>
        </div>
        <div className="d-flex justify-content-center w-100 mt-1 flex-wrap">
                <div className="hobbies-section pr-2 pt-1">
                    <img className="hobbies-image" src={homeImage} alt="home" />
                    <div className="d-flex text-section h-100">
                      <div className="text-center m-auto pl-2 pr-2">
                        <Link to="/homedesign">
                          <Button>
                            <Emoji symbol="✨" />See more <Emoji symbol="✨" />
                          </Button>   
                        </Link>
                      </div>  
                    </div>
                </div>
                <div className="hobbies-section pr-2 pt-1">
                    <img className="hobbies-image" src={clothesImage} alt="clothes" />
                    <div className="d-flex text-section h-100">
                      <div className="text-center m-auto pl-2 pr-2">
                        <Link to="/fits">
                          <Button>
                            <Emoji symbol="✨" />See more <Emoji symbol="✨" />
                          </Button>   
                        </Link>
                      </div>  
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default WhatILikePage;